import './home.scss'
import React, { Component } from 'react'
import {
  Colors,
  OnmailColors,
  LatestDownloadableOnmailIosLink,
} from './../../utils/constants'
import { Link } from 'react-router-dom'

/* base components */
import ProductVideo from './components/productVideo'
import Faq from './components/faq'
import BragLogos from './components/bragLogos'
import Hero from './components/hero'
import LargeStatement from './components/largeStatement'
import { getAnalytics, logEvent } from 'firebase/analytics'

/* variant components */
import OnmailFullWidthImageText from './../../components/onmailFullWidthImageText'
import OnmailFramedFullWidthImageText from './../../components/onmailFramedFullWidthImageText'
import OnmailLeftRightLayout from './../../components/onmailLeftRightLayout'

/* analytics + testing */

let screenViewReported = false

export default class OnmailHome extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount = () => {
    const analytics = getAnalytics()
    logEvent(analytics, 'positioningExperimentClick')
  }

  render() {
    return (
      <>
        <Hero
          //video={'https://player.vimeo.com/external/534586439.hd.mp4?s=4e7b800d3a3a96b4a1a0d5a55ce084247f5fec6f&profile_id=175'}
          //text={'Get a shorter and more memorable email address'}
          image="./images/marketing-block.png"
          subtitle={'Block Spam Before It Starts'}
          onButtonClick={() => this.props.trackLinkClick('4')}
          gradient={true}
        />
        <div className="black-background">
          <div className="max-width-container centered-text">
            <BragLogos />
            <img
              className="full-width"
              alt="Desktop and mobile phone screenshot together"
              src="/images/ui-combo.png"
            />
          </div>
        </div>
        <LargeStatement
          text={'Use your new email address to help reduce spam in your inbox'}
        />
        <ProductVideo />
        <OnmailFullWidthImageText
          title="The email address you wish you had"
          description=""
          alignment="centered-text"
          backgroundImage="/images/homepage-background.jpg"
        />
        <Faq />
      </>
    )
  }
}
