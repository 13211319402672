import React, { Component } from 'react'
import './index.scss'

export default class OnmailDiscontinue extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="full-width onmail-discontinue">
        <div
          className="background-frame-header background-image-fill"
          style={{ backgroundImage: 'url(/images/sunset-background.jpg)' }}
        >
          <div className="full-width full-height background-frame-overlay overlay">
            <div className="row full-height middle max-width-container">
              <div className="small-12 medium-12 large-12 padding-small centered-text">
                <h1 className="full-width white-text">So long...</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="padding-small">
          <div className="max-width-container discontinue-email">
            <p>Dear OnMail Community,</p>
            <br />
            <p>
              It is with a heavy heart that we inform our OnMail community of
              creators, small business owners and entrepreneurs that our journey
              together is coming to an end. Despite our best efforts to navigate
              the ever-changing and challenging business environment, we were
              unable to find product market fit for the OnMail email service and
              have made the difficult decision to discontinue its services on
              July 31st, 2025.
            </p>
            <br />
            <p>
              We want to extend our deepest gratitude to you — our incredible
              community. Your support, feedback, and passion for finding a
              better way to manage your email inboxes have been a driving force
              behind everything we’ve done. Together, we created something truly
              special, and we will always cherish the journey we shared.
            </p>
            <br />
            <p>
              I’d also like to express immense gratitude to the OnMail team.
              Building a fresh approach to email, one of the most widely used
              communication tools, was an ambitious task for a small team up
              against some of the biggest players in the tech world. The
              dedication, hard work, and sacrifices made by the team led to
              industry recognition, app store features, and coverage by major
              publishers — achievements that would not have been possible
              without their unwavering commitment.
            </p>
            <br />
            <p>
              I am deeply proud of the experience we built together, and I truly
              believe that the OnMail story will leave a lasting impact on how
              people think about email in the future.
            </p>
            <br />
            <p style={{ fontWeight: 700 }}>Next Steps</p>
            <br />
            <p>
              For current customers, we want to ensure a smooth transition. You
              will be able to export your data and transfer custom email domains
              up until July 31st, 2025. We encourage you to begin migrating your
              data and domain assets as soon as possible to avoid any
              disruption.
            </p>
            <br />
            <p>
              To help with this process, we’ve set up a dedicated page with all
              the information you need to transition to another service. You can
              access it here:
            </p>
            <br />
            <p>
              <a
                style={{ textDecoration: 'underline', color: '#136AEE' }}
                href="https://support.onmail.com/hc/en-us/articles/33888840094221-Exporting-your-OnMail-account-data-and-or-transfer-your-custom-domain"
                target="_blank"
              >
                OnMail Migration Guide
              </a>
            </p>
            <br />
            <p>
              Thank you again for being part of the OnMail story. We are truly
              grateful for your trust and loyalty throughout this journey.
            </p>
            <br />
            <p>Sincerely, Jeff</p>
          </div>
        </div>
      </div>
    )
  }
}
