import React, { Component } from 'react'
import { Colors } from './../../utils/constants'
import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import TrackedLink from './../../components/trackedLink'
import MaterialIcon from 'material-icons-react'
import { Helmet } from 'react-helmet'
import ScrollFadeIn from './../../components/scrollFadeIn'
import OnmailFramedFullWidthImageText from './../../components/onmailFramedFullWidthImageText'
import OnmailLeftRightLayout from './../../components/onmailLeftRightLayout'
import FullWidthParallaxLayeredBanner from './../../components/fullWidthParallaxLayeredBanner'
import VideoHero from './../../components/videoHero'
import Footer from './../../components/footer'
import { ReportFirebaseEvent } from './../../utils/analytics'
import './experience.scss'

let headerPhrases = [
  "it's your identity.",
  "it's a safe space.",
  "it's a smart assistant.",
]

export default class OnmailExperience extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPhraseIndex: 0,
      nextPhraseIndex: 0,
      headerPhrase: headerPhrases[0],
    }
  }
  componentDidMount = () => {
    var headerInterval = setInterval(this.headerTimer, 3500)
    // store intervalId in the state so it can be accessed later:
    this.setState({
      headerInterval: headerInterval,
    })
  }

  componentWillUnmount = () => {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.headerInterval)
  }

  headerTimer = () => {
    let nextPhraseIndex = this.state.nextPhraseIndex + 1
    let phraseIndex = this.state.nextPhraseIndex
    if (phraseIndex == headerPhrases.length - 1) {
      nextPhraseIndex = 0
    }

    this.setState(
      {
        active: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            headerPhrase: headerPhrases[phraseIndex],
            nextPhraseIndex: nextPhraseIndex,
            active: true,
          })
        }, 750)
      },
    )
  }

  trackExit = async event => {
    await ReportFirebaseEvent(event)
  }

  render() {
    let transitionClass = ''
    if (this.state.active) {
      transitionClass = 'active'
    }
    return (
      <div className="full-width onmail-experience-page">
        <Helmet>
          <title>Experience — Features Built for Today</title>
          <meta
            name="description"
            content="Total inbox control, import Gmail and other existing accounts to make them smarter in OnMail, get a free custom domain to personalize your email to represent you bettter, block spy pixels, and more."
          />
          <meta
            name="google-site-verification"
            content="yZ638yt0GZu1rqnAT2klj_g5IAsyi_33FQ_4MuBnptI"
          />
          <meta
            name="p:domain_verify"
            content="0703ddff50b36ba64a2edc3507c449ad"
          />
          <meta
            name="B-verify"
            content="3e7503ca0cb04368d4a783b926f0d84b95e74293"
          />
          <meta
            name="google-site-verification"
            content="JE63RlPVGqntnNKUZH5NpH3JO5SK2wPadGPkJ0FaIrY"
          />
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-MC8GN8X"></script>
          <meta name="theme-color" content="#2C2C2C" />
          <link rel="icon" type="image/png" href="/images/onmail-favicon.png" />
          /* OpenGraph tags */
          <meta
            property="og:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            property="og:description"
            content="Experience email built for today."
          />
          <meta
            property="og:image"
            content="/images/onmail-social-share-img.png"
          />
          <meta property="og:url" content="https://www.onmail.com" />
          /* Twitter Card tags */
          <meta
            name="twitter:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            name="twitter:description"
            content="Experience email built for today."
          />
          <meta
            name="twitter:image"
            content="/images/onmail-social-share-img-experience.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="theme-color" content="#2C2C2C" />
        </Helmet>

        <div className="show-for-small">
          <div
            className="background-image-fill"
            style={{
              backgroundImage: 'url(/images/experience-hero-mobile.jpg)',
              width: '100vw',
              height: '90vh',
            }}
          >
            <div
              className="row max-width-container centered-text"
              style={{ paddingTop: '80px' }}
            >
              <div className="small-12 medium-12 large-12 white-text padding-medium">
                <h1 className="padding-bottom-tiny">
                  {"It's not just another"}&nbsp;{'email,'}
                </h1>
                <h1 className={'animated-header-phrase ' + transitionClass}>
                  {this.state.headerPhrase}
                </h1>
              </div>
              <div className="small-12 medium-12 large-12">
                <img className="full-width" src="/images/square.png" alt=" " />
              </div>
            </div>
          </div>
        </div>

        <div className="hide-for-small">
          <VideoHero
            video="https://player.vimeo.com/external/534670260.hd.mp4?s=25283c7ec87b9ab08f2a44f6551d37ba1f83e120&profile_id=175"
            tintOpacity=".01"
          >
            <div
              className="overlay hero-video-overlay"
              style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className="row full-width middle max-width-container">
                <div className="small-12 medium-8 large-8">
                  <h1 className="">
                    {"It's not just another"}&nbsp;{'email,'}
                  </h1>
                  <h1 className={'animated-header-phrase ' + transitionClass}>
                    {this.state.headerPhrase}
                  </h1>
                </div>
                <div className="small-12 medium-4 large-4">
                  <img
                    className="full-width"
                    src="/images/square.png"
                    alt=" "
                  />
                </div>
              </div>
            </div>
          </VideoHero>
        </div>

        <div className="row full-screen-container background-onmail-extra-light-grey">
          <div className="max-width-container full-height centered-text section-padding">
            <div
              className="row full-height padding-small"
              style={{
                alignItems: 'center',
                alignContent: 'center',
                position: 'relative',
              }}
            >
              <h6 className="full-width">
                {'Switching email services has never been'}&nbsp;{'easier!'}
              </h6>
              <div className="row middle" style={{ alignItems: 'stretch' }}>
                <div className="small-12 medium-4 large-4 padding-tiny">
                  <div className="full-height padding-small">
                    <div className="">
                      <img
                        className="full-width"
                        src="/images/switching-new.png"
                        alt=""
                      />
                    </div>
                    <h4 className="onmail-black">
                      {'Create a new OnMail account and email'}&nbsp;
                      {'address.'}
                    </h4>
                  </div>
                </div>
                <div className="small-12 medium-4 large-4 padding-tiny">
                  <div className="full-height padding-small">
                    <div className="">
                      <img
                        className="full-width"
                        src="/images/switching-import.png"
                        alt=""
                      />
                    </div>
                    <h4 className="onmail-black">
                      {'Bring over your existing accounts. Modernize'}&nbsp;
                      {'them.'}
                    </h4>
                  </div>
                </div>
                <div className="small-12 medium-4 large-4 padding-tiny">
                  <div className="full-height padding-small">
                    <div className="">
                      <img
                        className="full-width"
                        src="/images/switching-go.png"
                        alt=""
                      />
                    </div>
                    <h4 className="onmail-black">
                      {'Experience a faster, smarter, and safer email'}&nbsp;
                      {'performance.'}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <OnmailLeftRightLayout
            title="End unwanted email."
            description="Accept only the senders you want in your inbox, and deny any that don’t belong in your space."
            image="/images/ui-accept-sender.png"
            backgroundImage="/images/ui-background.png"
            layout="left"
          />
        </div>
        <div className="">
          <OnmailLeftRightLayout
            title="Protect messages from snoops."
            description="Anti-tracking technology automatically blocks intrusive ad targeters from spying on your emails. Your inbox is yours alone."
            image="/images/ui-privacy.png"
            backgroundImage="/images/ui-background.png"
            layout="left"
          />
        </div>
        <div className="">
          <OnmailLeftRightLayout
            title="Share big ideas without limits."
            description="Never leave your inbox to send large attachments. Send files up to 200x larger than other providers."
            image="/images/ui-large-file-attachments.png"
            backgroundImage="/images/ui-background.png"
            layout="left"
          />
        </div>
        <div className="">
          <OnmailLeftRightLayout
            title="Clean up mail you've lost interest in."
            description="AI proactively offers to clean up mail you've lost interest in reading."
            image="/images/ui-unsubscribe.png"
            backgroundImage="/images/ui-background.png"
            layout="left"
          />
        </div>

        <div className="">
          <div className="section-padding">
            <div className={'max-width-container padding-small'}>
              <h6 className="padding-bottom-small">
                {'Level up your inbox game.'}
              </h6>
              <div className="row full-width">
                <div className="small-12 medium-6 large-6 background-onmail-extra-dark-grey padding-medium no-bottom-padding white-text">
                  <div
                    className="row full-height"
                    style={{
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <div className="margin-bottom-small">
                      <h2>{'Nudges'}</h2>
                      <h4 className="regular-text">
                        {
                          'Get periodic reminders to respond to important messages so you never drop the ball.'
                        }
                      </h4>
                    </div>
                    <img
                      className="full-width"
                      src="/images/ui-smart-features-2.png"
                      alt=" "
                    />
                  </div>
                </div>
                <div className="small-12 medium-6 large-6 background-onmail-dark-grey padding-medium no-bottom-padding white-text">
                  <div
                    className="row full-height"
                    style={{
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <div className="margin-bottom-small">
                      <h2>{'Smart responses'}</h2>
                      <h4 className="regular-text">
                        {'AI-generated responses help you respond with a tap.'}
                      </h4>
                    </div>
                    <img
                      className="full-width"
                      src="/images/ui-smart-features-3.png"
                      alt=" "
                    />
                  </div>
                </div>
              </div>

              <div className="row full-width">
                <div
                  className="small-12 medium-6 large-6 padding-medium no-bottom-padding white-text"
                  style={{ background: '#505666' }}
                >
                  <div
                    className="row full-height"
                    style={{
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <div className="margin-bottom-small">
                      <h2>{'Search'}</h2>
                      <h4 className="regular-text">
                        {
                          'Find anything you need using intuitive, natural language.'
                        }
                      </h4>
                    </div>
                    <img
                      className="full-width"
                      src="/images/ui-search.png"
                      alt=" "
                    />
                  </div>
                </div>
                <div
                  className="small-12 medium-6 large-6 padding-medium no-bottom-padding white-text"
                  style={{ background: '#676D78' }}
                >
                  <div
                    className="row full-height"
                    style={{
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <div className="margin-bottom-small">
                      <h2>{'Split Inbox'}</h2>
                      <h4 className="regular-text">
                        {
                          'Customize your inbox view. Keep tabs on important emails.'
                        }
                      </h4>
                    </div>
                    <img
                      className="full-width"
                      src="/images/ui-split-inbox.gif"
                      alt=" "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <OnmailLeftRightLayout
            title="Find everything you need."
            description="Smart Folders manage your packages, receipts, travel, and more so you never have to search."
            video="/images/ui-sift.mp4"
            backgroundImage="/images/ui-background.png"
            layout="left"
          />
        </div>

        <div className="section-padding background-onmail-extra-light-grey">
          <div className="max-width-container">
            <h2 className="centered-text">
              {'Designed for exceptional simplicity.'}
            </h2>
            <div className="max-width-container padding-small">
              <FullWidthParallaxLayeredBanner
                foregroundImage="/images/ui-combo-2.png"
                middlegroundImage="/images/ui-combo-1.png"
                backgroundImage="/images/ui-combo-background.png"
                ignoreResizeHack
              />
            </div>
          </div>
        </div>

        <div className="">
          <OnmailFramedFullWidthImageText
            title="Find harmony at last."
            description="Search that actually works. Access files, photos, and emails by contact. Mark messages you don’t need as Done to file them away for later and avoid clutter."
            backgroundImage="/images/experience-1-background.jpg"
          />
        </div>

        <div className="show-for-small">
          <OnmailFramedFullWidthImageText
            title="Work your way."
            description="Customize your email experience to best suit your workflow. Claim a custom domain or add an existing one."
            backgroundImage="/images/experience-2-mobile.jpg"
            theme={'dark'}
          />
        </div>
        <div className="hide-for-small">
          <OnmailFramedFullWidthImageText
            title="Work your way."
            description="Customize your email experience to best suit your workflow. Claim a custom domain or add an existing one."
            backgroundImage="/images/experience-2-background.jpg"
            theme={'dark'}
          />
        </div>

        <div className="background-onmail-primary">
          <div className="max-width-container padding-top-large padding-bottom-large">
            <div className="row middle">
              <div className="small-12 medium-9 large-9 padding-small center-for-small">
                <h2 className="white-text">{'Join the email revolution.'}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
