import React, { Component } from 'react'
import { GetNewsArticles } from './../../utils/functions'
import {
  Colors,
  LatestDownloadableMacFile,
  LatestDownloadableAndroidLink,
  LatestDownloadableIosLink,
} from './../../utils/constants'
import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import TrackedLink from './../../components/trackedLink'
import MaterialIcon from 'material-icons-react'
import { Helmet } from 'react-helmet'
import ScrollFadeIn from './../../components/scrollFadeIn'
import Footer from './../../components/footer'
import { withController, Parallax } from 'react-scroll-parallax'
import './team.scss'

export default class OnmailOurStory extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className="full-width onmail-our-story-page">
        <Helmet>
          <title>Our Team — From the Creators of Edison Mail</title>
          <meta
            name="description"
            content="Get OnMail. Made with care by actual email experts-- the pioneers of one-tap Unsubscribe and creators of the award-winning #1 independent email app, Edison Mail.  "
          />
          <meta
            name="google-site-verification"
            content="yZ638yt0GZu1rqnAT2klj_g5IAsyi_33FQ_4MuBnptI"
          />
          <meta
            name="p:domain_verify"
            content="0703ddff50b36ba64a2edc3507c449ad"
          />
          <meta
            name="B-verify"
            content="3e7503ca0cb04368d4a783b926f0d84b95e74293"
          />
          <meta
            name="google-site-verification"
            content="JE63RlPVGqntnNKUZH5NpH3JO5SK2wPadGPkJ0FaIrY"
          />
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-MC8GN8X"></script>
          <link rel="icon" type="image/png" href="/images/onmail-favicon.png" />
          /* OpenGraph tags */
          <meta
            property="og:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            property="og:description"
            content="Experience email built for today."
          />
          <meta
            property="og:image"
            content="/images/onmail-social-share-img.png"
          />
          <meta property="og:url" content="https://www.onmail.com" />
          /* Twitter Card tags */
          <meta
            name="twitter:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            name="twitter:description"
            content="Experience email built for today."
          />
          <meta
            name="twitter:image"
            content="/images/onmail-social-share-img-team.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="theme-color" content="#2C2C2C" />
        </Helmet>

        <div
          className="row middle full-screen-container background-image-fill background-custom"
          style={{ backgroundImage: 'url(/images/our-story-hero.jpg)' }}
        >
          <div className="max-width-container section-padding mobile-top-margin">
            <div className="row white-text">
              <div className="small-12 medium-4 large-4 padding-small">
                <p className="padding-bottom-tiny">
                  <b>{'Mikael Berner'}</b>
                  {
                    ' is Co-founder and CEO of Edison Software. He has been a successful founder and investor for over two decades. During that time his focus has been on finding ways to help improve consumer communication through AI.'
                  }
                </p>
                <p className="padding-bottom-tiny">
                  {
                    'Mikael first got involved in AI in 1999 when he co-founded BeVocal to provide speech automation services in the cloud. As Co-founder and CEO he grew BeVocal and sold it to Nuance Communications where under his leadership the BeVocal speech network became the largest cloud-based speech network in the world.'
                  }
                </p>
                <p className="padding-bottom-tiny">
                  {
                    'Mikael co-founded Edison Software with Hetal after leaving Nuance to create a better email experience. OnMail is the result of this goal — email that solves the core problems consumers have been facing for decades.'
                  }
                </p>
                <p>
                  {
                    'Mikael is an active inventor (he holds nine patents), board member and investor. He enjoys time with his wife, kids and extended family doing things like hiking, cooking, playing music and sailing.'
                  }
                </p>
              </div>
              <div className="small-12 medium-4 large-4 hide-for-small"></div>
              <div className="small-12 medium-4 large-4 padding-small">
                <p className="padding-bottom-tiny">
                  <b>{'Hetal Pandya'}</b>
                  {
                    ' is the Co-founder and VP of Marketing of Edison Software. As a second generation female tech entrepreneur, she was exposed to the transformative power of technology at a young age. With over twenty years of experience building and marketing products, Hetal has always had a passion for saving people time from mundane tasks and enabling technology to actually do smart things'
                  }
                  &mdash;{'seamlessly.'}
                </p>
                <p className="padding-bottom-tiny">
                  {
                    'Hetal has previously led product management and marketing at Nuance Communications, Microsoft (Tellme Networks) and Nortel Networks.'
                  }
                </p>
                <p className="padding-bottom-tiny">
                  {
                    'She co-founded Edison Software with Mikael ten years ago and has led the marketing team since then. With the launch of OnMail, she hopes consumers will realize how many hours and stress can be saved when you control your inbox.'
                  }
                </p>
                <p>
                  {
                    'Hetal is actively engaged in mentoring women in STEM and is active as an angel investor. When she isn’t working, Hetal enjoys cooking, travel adventures, time with family and her dog, Miso.'
                  }
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="background-onmail-extra-dark-grey">
          <div className="max-width-container padding-top-medium padding-bottom-medium">
            <div className="row middle">
              <div className="small-6 medium-3 large-3 padding-small">
                <img
                  className="full-width investor-logo"
                  src="/images/logo-nautilus-white.png"
                />
              </div>
              <div className="small-6 medium-3 large-3 padding-small">
                <img
                  className="full-width investor-logo"
                  src="/images/logo-mayfield-white.png"
                />
              </div>
              <div className="small-6 medium-3 large-3 padding-small">
                <img
                  className="full-width investor-logo"
                  src="/images/logo-cic-white.png"
                />
              </div>
              <div className="small-6 medium-3 large-3 padding-small">
                <img
                  className="full-width investor-logo"
                  src="/images/logo-usvp-white.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
