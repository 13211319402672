import React, { Component } from 'react'
import TrackedLink from './../../components/trackedLink'
import { Helmet } from 'react-helmet'
import Footer from './../../components/footer'
import PricingVariation1 from './pricingVariation1'
import PricingVariation2 from './pricingVariation2'
import { GetUrlVariable } from './../../utils/functions'
import './pricing.scss'

let pricingData = {
  monthly: {
    free: {
      planId: 0,
      price: '$0.00',
      description: 'Always free',
    },
    personal: {
      planId: 1,
      price: '$4.99',
      description: 'Save $10 by billing yearly',
    },
    pro: {
      planId: 2,
      price: '$9.99',
      description: 'Save $20 by billing yearly',
    },
  },
  yearly: {
    free: {
      planId: 4,
      price: '$0.00',
      description: 'Always free',
    },
    personal: {
      planId: 5,
      price: '$49.99',
      monthlyPrice: '$4.16',
      description: 'Save $10 per year',
    },
    pro: {
      planId: 6,
      price: '$99.99',
      monthlyPrice: '$8.33',
      description: 'Save $20 per year',
    },
  },
}

let featureData = {
  free: [
    '10GB storage',
    '100MB attachment file size limit',
    'Import Third-Party Accounts',
  ],
  personal: [
    '100GB storage',
    '250MB attachment file size limit',
    'Import Third-Party Accounts',
    'Password Protected Large File Links',
    'Custom Domain Support',
  ],
  pro: [
    '500GB storage',
    '1GB attachment file size limit',
    'Import Third-Party Accounts',
    'Password Protected Large File Links',
    'Custom Domain Support',
    'Branded File Transfer Pages',
    'Custom File Transfer URL',
    'Additional Domain Users',
  ],
}

export default class OnmailPricing extends Component {
  renderLayout(layout, pricingData, featureData) {
    console.log(layout)
    return (
      <PricingVariation2 pricingData={pricingData} featureData={featureData} />
    )
  }

  render() {
    let priceData = pricingData
    let layout = 1
    /*
    layout = Math.floor(Math.random() * 8);
    if(GetUrlVariable('v')){
      layout = parseInt(GetUrlVariable('v'));
    }
    */
    return (
      <div className="full-width onmail-pricing-page">
        <Helmet>
          <title>Pricing — OnMail Pricing</title>
          <meta name="description" content="Simple, affordable pricing." />
          <meta
            name="google-site-verification"
            content="yZ638yt0GZu1rqnAT2klj_g5IAsyi_33FQ_4MuBnptI"
          />
          <meta
            name="p:domain_verify"
            content="0703ddff50b36ba64a2edc3507c449ad"
          />
          <meta
            name="B-verify"
            content="3e7503ca0cb04368d4a783b926f0d84b95e74293"
          />
          <meta
            name="google-site-verification"
            content="JE63RlPVGqntnNKUZH5NpH3JO5SK2wPadGPkJ0FaIrY"
          />
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-MC8GN8X"></script>
          <meta name="theme-color" content="#2C2C2C" />
          <link rel="icon" type="image/png" href="/images/onmail-favicon.png" />
          /* OpenGraph tags */
          <meta
            property="og:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            property="og:description"
            content="Experience email built for today."
          />
          <meta
            property="og:image"
            content="/images/onmail-social-share-img.png"
          />
          <meta property="og:url" content="https://www.onmail.com" />
          /* Twitter Card tags */
          <meta
            name="twitter:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            name="twitter:description"
            content="Experience email built for today."
          />
          <meta
            name="twitter:image"
            content="/images/onmail-social-share-img-pricing.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="theme-color" content="#2C2C2C" />
        </Helmet>
        {this.renderLayout(layout, pricingData, featureData)}
      </div>
    )
  }
}
