import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './footer.scss'
export default class Footer extends Component {
  constructor(props) {
    super(props)
  }

  onPrivacyClick = event => {
    event.preventDefault()
    console.log('clicked')
    document.getElementById('clarip-do-not-sell-link').click()
  }
  render() {
    return (
      <div className="onmail-footer">
        <div className="section-padding background-onmail-black">
          <div className="max-width-container center-for-small">
            <div className="row margin-bottom-large">
              <div className="small-12 medium-5 large-5 padding-left-small padding-right-small padding-bottom-small">
                <h3 className="padding-bottom-tiny">
                  <Link to="/">Home</Link>
                </h3>
                <h3 className="padding-top-tiny padding-bottom-tiny">
                  <Link to="/vision">Vision</Link>
                </h3>
                <h3 className="padding-top-tiny padding-bottom-tiny">
                  <Link to="/team">Our Team</Link>
                </h3>
                <h3 className="padding-top-tiny padding-bottom-tiny">
                  <Link to="/experience">Experience</Link>
                </h3>
              </div>
              <div className="small-12 medium-4 large-4 padding-left-small padding-right-small">
                <p className="padding-bottom-small">
                  <Link to="/onmailPricing">Pricing</Link>
                </p>
                <p className="padding-bottom-small">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://support.onmail.com"
                  >
                    Support & Help
                  </a>
                </p>
                <p className="padding-bottom-small">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://mail.onmail.com/login"
                  >
                    Check My Email
                  </a>
                </p>
                <p className="padding-bottom-small">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://mail.onmail.com/forgot"
                  >
                    Reset Password
                  </a>
                </p>
              </div>
              <div className="small-12 medium-3 large-3 padding-left-small padding-right-small">
                <p className="padding-bottom-small">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.edison.tech/privacy"
                  >
                    Privacy Policy
                  </a>
                </p>
                <p className="padding-bottom-small">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.edison.tech/terms"
                  >
                    Terms
                  </a>
                </p>
                <p className="padding-bottom-small">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.edisonmail.com/us-residents"
                  >
                    CA Notice at Collection
                  </a>
                </p>
                <p
                  className="padding-bottom-small"
                  onClick={this.onPrivacyClick}
                >
                  <a>
                    <span
                      id="clarip-do-not-sell-link"
                      style={{ display: 'inline-flex' }}
                    >
                      <span>Your Privacy Choices</span>
                      <img
                        style={{ width: '40px', marginLeft: '10px' }}
                        src="https://oag.ca.gov/sites/all/files/agweb/images/privacy/privacyoptions.svg"
                      />
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="padding-small">
              <img
                alt="OnMail company logo"
                className="onmail-footer-logo"
                src="/images/onmail-logo-grey.png"
              />
            </div>

            <div className="row padding-left-small padding-right-small">
              <div className="small-12 medium-4 large-5">
                <div className="margin-right-tiny inline">
                  <a
                    href="https://www.facebook.com/EdisonApps/"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      alt="Facebook's social media icon"
                      className="onmail-footer-icon"
                      src="/images/icon-social-fb.png"
                    />
                  </a>
                </div>
                <div className="margin-right-tiny margin-left-tiny inline">
                  <a
                    href="https://www.linkedin.com/company/easilydo-inc-/"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      alt="LinkedIn's social media icon"
                      className="onmail-footer-icon"
                      src="/images/icon-social-linkedin.png"
                    />
                  </a>
                </div>
                <div className="margin-right-tiny margin-left-tiny inline">
                  <a
                    href="https://www.instagram.com/edison_apps/"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      alt="Instagram social media icon"
                      className="onmail-footer-icon"
                      src="/images/icon-social-insta.png"
                    />
                  </a>
                </div>
                <div className="margin-right-tiny margin-left-tiny inline">
                  <a
                    href="https://twitter.com/getonmail"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      alt="Twitter's social media icon"
                      className="onmail-footer-icon"
                      src="/images/icon-social-twitter.png"
                    />
                  </a>
                </div>
                <div className=" margin-left-tiny inline">
                  <a
                    href="https://medium.com/@EdisonApps"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      alt="Medium social media icon"
                      className="onmail-footer-icon"
                      src="/images/icon-social-medium.png"
                    />
                  </a>
                </div>
              </div>
              <div className="small-12 medium-8 large-7 right-text onmail-white mobile-co-padding">
                <p className="subheading" style={{ fontSize: '.75em' }}>
                  &copy; 2022 Edison Software
                </p>
              </div>
            </div>

            <div className="padding-small margin-top-large">
              <a
                href="//privacy.truste.com/privacy-seal/validation?rid=a4d9695c-f430-48d5-9980-f78c11cd7c5f"
                target="_blank"
                rel="noopener"
              >
                <img
                  className="truste"
                  style={{ border: 'none' }}
                  src="//privacy-policy.truste.com/privacy-seal/seal?rid=a4d9695c-f430-48d5-9980-f78c11cd7c5f"
                  alt="TRUSTe"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
